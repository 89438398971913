<template>
  <div>
    <a-row :gutter="[8,8]">
      <template v-if="orders.length">
        <OrderDeskColumn
          v-for="(col, index) in orders"
          :key="index"
          :column-data="col"
          :color="col.color"
          @open-modal="handleModalOpen">
        </OrderDeskColumn>
      </template>
      <template v-else>
        <a-space class="spin-space-layout">
          <a-spin size="small"/>
          <a-spin/>
          <a-spin size="large"/>
        </a-space>
      </template>
    </a-row>

    <a-modal v-model:open="openModal" width="1000px" @ok="handleModalSubmit" @cancel="handleModalClose">

      <div class="modal-data-block">
        <div class="modal-data-item">
          <span style="color: rgb(131, 146, 171);">Номер:</span> {{ currentOrder.id }} <b>{{ currentOrder.stage }}</b>
        </div>
        <div class="modal-data-item">
          <span style="color: rgb(131, 146, 171);">Номер (СберМаркет):</span>
          {{ currentOrder.originalOrderId }}
        </div>
      </div>

      <div class="card-flex-row">

        <div class="card-left-block">

          <div
            class="modal-data-block"
            v-if="currentOrder.cancellation && currentOrder.cancellation.description"
          >
            <div class="modal-data-title"><b>Заказ отменен</b></div>
            <div class="modal-data-item">
              <span style="color: rgb(131, 146, 171);">Причина:</span>
              {{ currentOrder.cancellation.description }}
            </div>
          </div>

          <div class="modal-data-block">
            <div class="modal-data-title"><b>Клиент</b></div>
            <div class="modal-data-item">
              <span style="color: rgb(131, 146, 171);">Имя:</span>
              {{ currentOrder.customer.name }}
            </div>
            <div class="modal-data-item">
              <span style="color: rgb(131, 146, 171);">Номер:</span>
              {{ currentOrder.customer.phone }}
            </div>
            <div class="modal-data-item">
              <span style="color: rgb(131, 146, 171);">Доб. код:</span>
              {{ currentOrder.customer.short_number }}
            </div>
          </div>

          <div class="modal-data-block">
            <div class="modal-data-title"><b>Доставка</b></div>
            <div class="modal-data-item">
              <span style="color: rgb(131, 146, 171);">Запланировано:</span>
              {{ currentOrder.delivery.isPlanned ? 'Да' : 'Нет' }}
            </div>
            <div class="modal-data-item">
              <span style="color: rgb(131, 146, 171);">Доставка от:</span>
              {{ orderDeliveryDate(currentOrder.delivery.expectedFrom) }}
            </div>
            <div class="modal-data-item">
              <span style="color: rgb(131, 146, 171);">Доставка до:</span>
              {{ orderDeliveryDate(currentOrder.delivery.expectedTo) }}
            </div>

            <template v-if="currentOrder.courier">
              <div class="modal-data-item">
                <span style="color: rgb(131, 146, 171);">Имя курьера:</span>
                {{ currentOrder.courier.name }}
              </div>
              <div class="modal-data-item">
                <span style="color: rgb(131, 146, 171);">Номер курьера:</span>
                {{ currentOrder.courier.phone }}
              </div>
            </template>
          </div>

          <div class="modal-data-block">
            <div class="modal-data-title"><b>Оплата</b></div>
            <div class="modal-data-item">
              <span style="color: rgb(131, 146, 171);">Сумма до скидок:</span>
              {{ currentOrder.total.totalPrice }} ₽
            </div>
            <div class="modal-data-item">
              <span style="color: rgb(131, 146, 171);">Сумма со скидкой: </span>
              <b>{{ currentOrder.total.discountTotalPrice }}</b> ₽
            </div>
            <div class="modal-data-item">
              <span style="color: rgb(131, 146, 171);">Тип оплаты:</span>
              {{ currentOrder.paymentMethods.join(', ') }}
            </div>
          </div>
        </div>
        <div class="card-right-block">

          <div class="modal-data-block">
            <div class="modal-data-title"><b>Параметры</b></div>
            <div class="modal-data-item">
              <span style="color: rgb(131, 146, 171);">Оплачен:</span>
              {{ currentOrder.paid ? 'Да' : 'Нет' }}
            </div>
            <div class="modal-data-item">
              <span style="color: rgb(131, 146, 171);">Отменен:</span>
              {{ currentOrder.cancelled ? 'Да' : 'Нет' }}
            </div>
            <div class="modal-data-item">
              <span style="color: rgb(131, 146, 171);">Комментарий:</span>
              {{ currentOrder.comment }}
            </div>
            <div class="modal-data-item">
              <span style="color: rgb(131, 146, 171);">Политика отмены заказа:</span>
              {{ currentOrder.cancellationReason }}
            </div>
            <div class="modal-data-item">
              <span style="color: rgb(131, 146, 171);">Тип доставки:</span>
              {{ currentOrder.shipmentMethod }}
            </div>
          </div>

          <div class="modal-data-block" v-if="currentOrder.address">
            <div class="modal-data-title"><b>Адрес доставки</b></div>
            <div class="modal-data-item">
              <span style="color: rgb(131, 146, 171);">Полный адрес:</span>
              {{ currentOrder.address.full_address }}
            </div>
            <div class="modal-data-item">
              <span style="color: rgb(131, 146, 171);">До двери:</span>
              {{ currentOrder.address.delivery_to_door ? 'Да' : 'Нет' }}
            </div>
            <div class="modal-data-item">
              <span style="color: rgb(131, 146, 171);">Широта:</span>
              {{ currentOrder.address.lat }}
            </div>
            <div class="modal-data-item">
              <span style="color: rgb(131, 146, 171);">Долгота:</span>
              {{ currentOrder.address.lon }}
            </div>
            <div class="modal-data-item">
              <span style="color: rgb(131, 146, 171);">Город:</span>
              {{ currentOrder.address.city }}
            </div>
            <div class="modal-data-item">
              <span style="color: rgb(131, 146, 171);">Улица:</span>
              {{ currentOrder.address.street }}
            </div>
            <div class="modal-data-item">
              <span style="color: rgb(131, 146, 171);">Дом:</span>
              {{ currentOrder.address.building }}
            </div>
            <div class="modal-data-item">
              <span style="color: rgb(131, 146, 171);">Подъезд:</span>
              {{ currentOrder.address.entrance }}
            </div>
            <div class="modal-data-item">
              <span style="color: rgb(131, 146, 171);">Домофон:</span>
              {{ currentOrder.address.door_phone }}
            </div>
            <div class="modal-data-item">
              <span style="color: rgb(131, 146, 171);">Этаж:</span>
              {{ currentOrder.address.floor }}
            </div>
            <div class="modal-data-item">
              <span style="color: rgb(131, 146, 171);">Квартира:</span>
              {{ currentOrder.address.apartment }}
            </div>
            <div class="modal-data-item">
              <span style="color: rgb(131, 146, 171);">Лифт:</span>
              {{ currentOrder.address.elevator }}
            </div>
          </div>
        </div>
      </div>

      <div class="modal-data-block">
        <div class="modal-data-title"><b>Товары</b></div>

        <a-button
          v-if="currentOrder.permissions.includes('products')"
          class="editable-add-btn" style="margin-bottom: 8px; float: right;"
          @click="handleProductRowAdd">
          Добавить товар
        </a-button>
        <br v-else/>

        <a-table bordered :data-source="tableData" :columns="tableProductColumns" :pagination="false">
          <template #bodyCell="{ column, text, record }">
            <template v-if="column.dataIndex === 'edit' && currentOrder.permissions.includes('products')">
              <div class="editable-cell">
                <div class="editable-cell-input-wrapper">
                  <check-outlined
                    v-if="editableTableData[record.key]"
                    class="editable-cell-icon-check"
                    @click="onProductSave(record.key)"/>
                  <edit-outlined
                    v-else
                    class="editable-cell-icon"
                    @click="onProductEdit(record.key)"/>
                </div>
              </div>
            </template>
            <template v-if="column.dataIndex !== 'edit' && column.dataIndex !== 'operation'">
              <div class="editable-cell">
                <div v-if="editableTableData[record.key]" class="editable-cell-input-wrapper">
                  <a-input v-model:value="editableTableData[record.key][column.dataIndex]"
                           @pressEnter="onProductSave(record.key)"/>
                </div>
                <div v-else class="editable-cell-text-wrapper">
                  {{ text }}
                </div>
              </div>
            </template>
            <template v-else-if="column.dataIndex === 'operation' && currentOrder.permissions.includes('products')">
              <a-popconfirm
                v-if="tableData.length"
                ok-text="Да"
                cancel-text="Нет"
                title="Уверены, что хотите отменить товар?"
                @confirm="onDelete(record.key)"
              >
                <delete-outlined/>
              </a-popconfirm>
            </template>
          </template>
        </a-table>
      </div>

      <template #footer>
        <a-popconfirm
          v-if="currentOrder.permissions.includes('cancel')"
          title="Уверены, что хотите отменить заказ?"
          ok-text="Да"
          cancel-text="Нет"
          @confirm="handleModalCancel"
        >
          <a-button key="back" :loading="loading" danger>Отменить</a-button>
        </a-popconfirm>
        <a-button
          v-if="currentOrder.permissions.includes('applyOrder')"
          key="submit"
          type="primary"
          :loading="loading"
          @click="handleModalSubmit('apply')">
          Принят в работу
        </a-button>
        <a-button
          v-if="currentOrder.permissions.includes('orderReady')"
          key="submit"
          type="primary"
          :loading="loading"
          @click="handleModalSubmit('ready')">
          Собран
        </a-button>
        <a-button
          v-if="currentOrder.permissions.includes('pickup')"
          key="submit"
          type="primary"
          :loading="loading"
          @click="handleModalSubmit('pickup')">
          Клиент забрал
        </a-button>
      </template>

    </a-modal>

  </div>
</template>

<script>
import OrderDeskColumn from "@/components/order/OrderDeskColumn";
import {CheckOutlined, EditOutlined, DeleteOutlined} from "@ant-design/icons-vue";
import {message} from "ant-design-vue";

export default {
  name: 'order-desk',
  props: {},
  components: {
    OrderDeskColumn,
    CheckOutlined, EditOutlined, DeleteOutlined
  },
  mounted() {
    this.getOrders();
    setInterval(this.getOrders, 15000)
  },
  data() {
    return {
      cardBodyStyle: {
        padding: '7px',
      },
      loading: false,
      openModal: false,
      tableProductColumns: [
        {
          title: '',
          dataIndex: 'edit',
        },
        {
          title: 'ID товара',
          dataIndex: 'id',
        },
        {
          title: 'Наименование',
          dataIndex: 'name',
          width: '30%',
        },
        {
          title: 'Кол-во',
          dataIndex: 'quantity',
        },
        {
          title: 'Вес',
          dataIndex: 'weight',
        },
        {
          title: 'Цена со скидкой',
          dataIndex: 'discountPrice',
        },
        {
          title: 'Стоимость',
          dataIndex: 'price',
        },
        {
          title: '',
          dataIndex: 'operation',
        },
      ],
      tableData: [],
      editableTableData: [],
      orders: [],
      currentOrder: {},
    }
  },
  methods: {
    async getOrders() {
      this.orders = [];
      const response = await this.$http.postForm('/api/', {
        'method': 'order',
        'action': 'list',
        'data': {}
      });

      if (response.data && response.data.status) {
        const ordersResponse = response.data.response;

        for (const colName in ordersResponse) {
          this.orders.push(ordersResponse[colName]);
        }
      } else {
        message.error({content: response.data.response, duration: 5});
      }
    },
    async handleModalSubmit(type) {
      this.loading = true;
      const response = await this.$http.postForm('/api/', {
        'method': 'order',
        'action': type,
        'data': {
          'orderId': this.currentOrder.originalOrderId,
          'positions': this.tableData
        }
      });

      if (!response.data.status) {
        message.error({content: response.data.response, duration: 5});
      } else {
        await this.getOrders();
        this.closeModal();
      }

      this.loading = false;
    },
    async handleModalCancel() {
      this.loading = true;
      const response = await this.$http.postForm('/api/', {
        'method': 'order',
        'action': 'cancel',
        'data': {
          'orderId': this.currentOrder.originalOrderId
        }
      });

      console.log(response);
      console.log(response.data);

      if (!response.data.status) {
        message.error({content: response.data.response, duration: 5});
      } else {
        await this.getOrders();
        this.closeModal();
      }
      this.loading = false;
    },
    handleModalClose() {
      this.clearModalData();
    },
    clearModalData() {
      this.tableData = [];
      this.editableTableData = [];
    },
    closeModal() {
      this.openModal = false;
      this.clearModalData();
    },
    handleModalOpen(order, permissions) {
      this.clearModalData();
      this.currentOrder = order;
      this.openModal = true;
      this.currentOrder.permissions = permissions;
      for (let i = 0; i < order.positions.length; i++) {
        this.tableData.push({
          key: i,
          id: order.positions[i].id,
          replacedByID: '',
          name: order.positions[i].name,
          quantity: order.positions[i].quantity,
          originalQuantity: order.positions[i].originalQuantity,
          weight: order.positions[i].weight,
          price: order.positions[i].price,
          discountPrice: order.positions[i].discountPrice,
          totalPrice: order.positions[i].totalPrice,
          totalDiscountPrice: order.positions[i].totalDiscountPrice,
        });
      }
    },
    handleProductRowAdd() {
      const newData = {
        key: `${this.tableData.length}`,
        id: null,
        name: 'Название товара',
        quantity: 1,
        originalQuantity: 1,
        weight: '',
        price: 0,
        discountPrice: 0,
        totalPrice: 0,
        totalDiscountPrice: 0,
      };
      this.tableData.push(newData);
    },
    orderDeliveryDate(date) {
      const dateInstance = new Date(date);
      const month = dateInstance.getMonth();
      const fullDate = dateInstance.getDate() + '.' + (month < 10 ? '0' + month : month) + '.' + dateInstance.getFullYear();
      const hours = dateInstance.getHours();
      const minutes = dateInstance.getMinutes();
      return fullDate + ', ' + (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes);
    },
    onProductEdit(key) {
      this.editableTableData[key] = this.tableData.filter(item => key === item.key)[0];
    },
    onProductSave(key) {
      this.tableData[key] = {...this.editableTableData[key]};
      delete this.editableTableData[key];
    },
    onDelete(key) {
      if(this.tableData[key].id) {
        this.tableData[key].quantity = 0;
        this.tableData[key].weight = this.tableData[key].weight > 0 ? 0 : "";
      } else {
        delete this.tableData[key];
      }
      delete this.editableTableData[key];
    }
  }
}
</script>

<style>
.modal-data-block {
  font-size: 16px;
  margin-top: 10px;
}

.card-left-block, .card-right-block {
  width: 50%;
  flex-wrap: wrap;
  align-self: start;
}

.spin-space-layout {
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
}
</style>
